import React from "react";

import { StyleSheet, css } from "aphrodite";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const styles = StyleSheet.create({
  researchBox: {
    marginTop: "1em",
    marginBottom: "2.5em"
  },
  subtext: {
    fontStyle: "italic"
  }
});

export default function Research() {
  return (
    <div
      style={{
        background: "url(bg2.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <Container className="pageContainer" id="research">
        <Row>
          <Col md={{ span: 4 }}>
            <h2 style={{ fontSize: 36 }}>Research</h2>
          </Col>
        </Row>
        <Row style={{ marginTop: "2em" }}>
          <Col md={{ span: 6 }}>
            <h3 style={{ fontSize: 24 }}>Current</h3>
            <div style={{ borderBottom: "1px solid #000" }}></div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6 }}>
          <div className={css(styles.researchBox)}>
              <p>
                Gerontocracy
              </p>
              <p className={css(styles.subtext)}>
                Recipient of Captian Jonathan Fay Prize and Hoopes Prize for Best Thesis at Harvard College, 2023. 
                Harvard College Research Program (HCRP) grant recipient, 2022.
              </p>
              <p>
              Respect for elders (or lack thereof) is a central pillar of many social structures, where norms range from filial piety to geronticide. 
              Despite their importance and substantial cultural variation, these beliefs are not well understood. What caused this variation, 
              and what consequences do these beliefs have today? I first examine the origins of elder norms, creating a measure of historical 
              attitudes toward elders based on over 1,200 folklore motifs spanning different cultures. Using historical environmental data on climatic stability 
              and subsistence complexity as instruments, I find the importance of elders in society is increasing in the likelihood their advice 
              remains relevant in future generations and the cost of “reinventing the wheel”. Next, I extend this model to present-day outcomes. 
              I find historical support for elders explains both cross- and within-country variation in outcomes ranging from old-age mortality to 
              implicit bias. I show how attitudes towards elders manifest at the polling booth in older and less democratic leaders both within 
              and across countries. Finally, I model the influence of gerontocracy specifically using an instrumented mediation analysis, 
              providing evidence for the contribution of elder norms to the fragility of democracy as a whole.
              </p>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "2em" }}>
          <Col md={{ span: 6 }}>
            <h3 style={{ fontSize: 24 }}>Past</h3>
            <div style={{ borderBottom: "1px solid #000" }}></div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6 }}>

          <div className={css(styles.researchBox)}>
              <p>
                Trade Agreements and Protectionism's Shadow: Evidence from Japan
                (with{" "}
                <a
                  href="https://sites.google.com/view/ryomakioka/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Makioka Ryo
                </a>
                ), RIETI Discussion Paper Series (
                <a
                  href="https://www.dropbox.com/s/ibf8bwddi6o1jgn/Saenger_Makioka_DP_Draft_v2-3.pdf?dl=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  draft
                </a>
              ), Presentations @ FRB International Finance, Japan Society of International Economics (
                <a
                  href="https://www.dropbox.com/s/ibf8bwddi6o1jgn/Saenger_Makioka_DP_Draft_v2-3.pdf?dl=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  english slides
                </a>
              ).
              </p>
              <p className={css(styles.subtext)}>
                Grants: Reischauer Institute of Japanese Studies (Summer
                Research Grant), Harvard University Asia Center (Winter Research
                Grant)
              </p>
            </div>
            <div className={css(styles.researchBox)}>
              <p>
                Unmasking Tourism in Venice: How tourism dynamics unfold within
                the built environment (MIT Civic Data Design Lab),{" "}
                <a
                  href="http://overtourismvenice.mit.edu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  website
                </a>
              , press in{" "}
              <a
                href="https://www.urbanet.info/an-epidemic-might-cure-venice/"
                target="_blank"
                rel="noopener noreferrer"
              >
                URBANET
              </a>
              {" "}(a publication of the German Federal Ministry for Economic Cooperation and Development)
              </p>
              <p className={css(styles.subtext)}>
                Grant: Harvard Center for European Studies (Thesis Grant)
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            (<a href="#top">Back</a>)
          </div>
        </Row>
      </Container>
    </div>
  );
}
