import React, { useState } from "react";

import Top from "../components/top";
import Research from "../components/research";
import About from "../components/about";
import Design from "../components/design";

function Portfolio() {
  const [aboutOpen, setAboutOpen] = useState(false);

  const handleAboutClick = () => {
    setAboutOpen(!aboutOpen);
  };
  return (
    <div className="Portfolio">
      {aboutOpen ? (
        <About handleAboutClick={handleAboutClick} />
      ) : (
        <>
          <Top handleAboutClick={handleAboutClick} />
          <Research />
          <Design />
        </>
      )}
    </div>
  );
}

export default Portfolio;
